<template>
  <v-card
      flat
      class="card w-full pt-1 pb-5"
  >
    <div class="flex justify-start">
      <v-text-field
          label="Name"
          ref="locationName"
          class="locationNameField pl-2"
          inline
          outlined
          :color="locationNameFieldColor"
          v-model="locationName"
          :hint="locationNameFieldHint"
          hide-details="auto"
          :persistent-hint="!!locationNameFieldHint"
          :append-icon="locationNameAppendIcon"
          @focus="locationNameAppendIcon = 'mdi-checkbox-marked-circle'"
          @blur="locationNameAppendIcon = undefined"
          @click:append="changeLocationInfo('Location name')"
      />

      <div class="block locationAddressField">
        <label :for="`locationAddress-${uuid}`" :class="`block addressFieldLabel ${addressLabelIsTop ? 'addressFieldLabel__top' : null}`">
          Address
        </label>
        <VueGoogleAutocomplete
            :id="`locationAddress-${uuid}`"
            label="Address"
            ref="locationAddress"
            classname="pl-2 addressTextField"
            :country="['us']"
            :fields="fieldsForAutocomplete"
            placeholder=""
            @focus="isAddressFocused = true"
            @blur="isAddressFocused = false"
            @inputChange="onLocationInputValChange"
            v-on:placechanged="onLocationInputChange">
        </VueGoogleAutocomplete>
      </div>

      <div class="flex locationRotateField">
        <v-select
            outlined
            label="Data Retention"
            :items="rotateDaysValues"
            item-text="label"
            item-value="days"
            v-model="locationRotateDays"
            class="pl-2"
            :hint="locationRotateDaysFieldHint"
            hide-details="auto"
            :persistent-hint="!!locationRotateDaysFieldHint"
            @change="changeLocationInfo('Location rotate days')"
        />

        <v-tooltip top color="primary" content-class="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                class="my-2 searchBtn"
                icon
            >
              <v-icon size="22" color="#086C97">mdi-information</v-icon>
            </v-btn>
          </template>
          <div style="max-width: 300px">
            This option allows you to specify how long you want to keep your data stored within the system.
            By selecting a timeframe, you're determining the duration for which your data will be retained.
          </div>
        </v-tooltip>
      </div>

      <v-switch
          v-model="locationEnabled"
          color="#66AB99"
          class="switchElem pl-2 mt-2"
          @change="toggleLocationEnabled"
      ></v-switch>

      <v-btn text class="mb-2 pl-2 mt-2 gray--text" @click="deleteLocation" v-if="canDisableOrRemove">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
    <div class="flex justify-start w-12/12 pa-3">
      <span class="text-gray text-xs pr-10">Practitioners: <b>{{ practitionersCount }}</b></span>
      <span class="text-gray text-xs pr-10">Rooms: <b>{{ roomsCount }}</b></span>
      <span class="text-gray text-xs pr-10">Treatment types: <b>{{ treatmentTypesCount }}</b></span>
      <span class="text-gray text-xs pr-10" v-if="!!createdAt">Date-created: <b>{{ createdAt }}</b></span>
      <span
          class="text-gray text-xs pr-10"
          v-if="!!locationTimeZone"
      >
        Time zone:
        <b>{{ locationTimeZone }}</b>
      </span>
      <span
          class="text-gray text-xs goToLocation"
          style="color: rgb(47, 107, 147)"
          @click="goToOffice(uuid)"
          v-if="canGoToLocation">
        Go to Location</span>
    </div>
    <div>
      <h5 class="text-gray text-sm pl-3">Practices at this location (will be merged)</h5>

      <v-chip
          v-if="locationOffices.length > 0"
          v-for="(office, i) in locationOffices"
          :key="`location-${uuid}-${office.uuid}-${i}`"
          class="ma-2"
          close
          @click:close="changeOffice('Removing', office.uuid)"
      >
        {{ office.name }}
      </v-chip>

      <v-menu
          :nudge-width="130"
          v-if="practicesForSelect.length > 0"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
              text
              class="buttonAddLocation"
              x-small
              v-bind="attrs"
              v-on="on">
            <v-icon>mdi-plus</v-icon> Add
          </v-btn>
        </template>

        <v-list class="scroll-section">
          <v-list-item
              v-for="(practice, i) in practicesForSelect"
              :key="`add-location-${practice.uuid}-${uuid}`"
              @click="changeOffice('Adding', practice.uuid)"
          >
            <v-list-item-title>
              {{ practice.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
          text
          class="buttonAddLocation"
          x-small
          v-if="practicesForSelect.length > 0"
          @click="changeOffice('Adding all')"
      >
        <v-icon>mdi-plus</v-icon> All
      </v-btn>
    </div>

  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Timezone from "node-google-timezone";


export default {
  name: "SettingsLocationCard",
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    uuid: String,
    name: { type: String, default: '' },
    address: { type: String, default: '' },
    position: { type: Object, default: '' },
    rotateDays: { type: String, default: null },
    timeZone: { type: String, default: '' },
    enabled: { type: Boolean, default: false },
    offices: Array,
    practitionersCount: Number,
    roomsCount: Number,
    treatmentTypesCount: Number,
    createdAt: String,
  },
  data() {
    return {
      locationName: '',
      locationAddress: '',
      locationPosition: undefined,
      locationAddressObject: undefined,
      locationTimeZone: undefined,
      locationEnabled: false,
      locationOffices: [],
      locationNameFieldColor: '#056C97',
      locationNameAppendIcon: undefined,
      locationNameFieldHint: undefined,
      locationAddressFieldColor: '#056C97',
      locationAddressAppendIcon: undefined,
      locationAddressFieldHint: undefined,
      locationRotateDays: null,
      locationRotateDaysAppendIcon: undefined,
      locationRotateDaysFieldHint: undefined,
      fieldsForAutocomplete:["address_components", "geometry", "icon", "name"],
      isAddressFocused: false,
      rotateDaysValues: [
        {days: 7, label: '1 Week'},
        {days: 30, label: '1 Month'},
        {days: 365, label: '1 Year'},
        {days: null, label: 'Always'},
      ],
    }
  },
  mounted() {
    this.updateFromProps()
  },
  computed: {
    ...mapState({
      locationsAll: state => state.locationsAll,
      practicesAll: state => state.practicesAll,
      userSelectedOffice: state => state.auth.office,
    }),
    practicesForSelect() {
      const practicesUUIDs = []
      this.locationOffices?.map( practice => practicesUUIDs.push(practice.uuid) )
      return this.practicesAll.filter((practice) => !practicesUUIDs.includes(practice.uuid))
    },
    canDisableOrRemove() {
      return this.userSelectedOffice !== this.uuid
    },
    canGoToLocation() {
      return this.uuid !== this.userSelectedOffice && this.enabled
    },
    addressLabelIsTop() {
      return this.isAddressFocused || this.locationAddress?.length > 1
    }
  },
  watch: {
    locationsAll() {
      this.updateFromProps();
    },
    locationName() {
      if (this.locationName === this.name) {
        this.$emit('set-allowed-close', true);
        this.locationNameFieldHint = undefined;
      } else {
        this.$emit('set-allowed-close', false);
        this.locationNameFieldColor = 'yellow darken-1';
        this.locationNameFieldHint = 'NOT SAVED';
      }
    },
    locationAddress() {
      if (this.locationAddress === this.address) {
        this.$emit('set-allowed-close', true);
        this.locationAddressFieldHint = undefined;
      } else {
        this.$emit('set-allowed-close', false);
        this.locationAddressFieldColor = 'yellow darken-1';
        this.locationAddressFieldHint = 'NOT SAVED';
      }
    },
  },
  methods: {
    ...mapActions([
      'CLEAR_HISTORY',
      'LOAD_APPOINTMENTS',
      'LOAD_DASHBOARD',
      'LOAD_DOCTORS',
      'LOAD_TREATMENTS',
      'SUBSCRIBE_TO_CLIENT_RELOAD',
      'UNSUBSCRIBE_ALL',
    ]),
    updateFromProps() {
      this.locationName = this.name;
      this.locationAddress = this.address;
      this.locationEnabled = this.enabled;
      this.locationOffices = this.offices;
      this.locationTimeZone = this.timeZone;
      this.locationRotateDays = this.rotateDays;

      // library vue-google-autocomplete has not props to pass current value
      document.getElementById(`locationAddress-${this.uuid}`).value = this.address
    },
    async toggleLocationEnabled() {

      let action = '';

      if (this.locationEnabled){
        action = 'enable'
      } else {
        action = 'disable'
      }

      const payload = {
        locationUUID: this.uuid,
        action: action,
      }

      const success = await this.$store.dispatch('LOCATION_ACTIVITY_UPDATE', payload)

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `Location ${this.name} ${action}d successful .` });
        this.updateFromProps()

        if (this.uuid === this.userSelectedOffice) {
          const nextOffice = this.$store.state.auth.user.offices.filter( off => off.uuid !== this.uuid)[0]
          console.log('next office', nextOffice)
          await this.goToOffice(nextOffice.uuid)
        }
      } else {
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while ${action} location ${this.name}.` });
        this.updateFromProps()
      }

    },
    async changeOffice(action, uuid = '') {
      let locations = []

      if (action === 'Removing') {
        this.locationOffices.filter((office) => office.uuid !== uuid).map((office) => {
          locations.push(office.uuid)
        })
      } else if(action === 'Adding') {

        this.locationOffices.map((office) => {
          locations.push(office.uuid)
        })
        locations = [...locations, uuid]

      } else if(action === 'Adding all') {

        this.practicesAll.map((office) => {
          locations.push(office.uuid)
        })
      } else {
        return false
      }

      const payload = {
        locationUUID: this.uuid,
        changed: 'office',
        data: {
          name: this.name,
          address: this.address,
          offices: locations ?? [],
        }
      }

      const success = await this.$store.dispatch('UPDATE_LOCATION', payload)

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `${action} office from ${this.name}.` });
        this.updateFromProps()
      } else {
        this.userLocations = this.locations
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while ${action} office from ${this.name}.` });
      }
    },
    clearHints() {
      this.locationNameFieldHint = undefined;
      this.locationAddressFieldHint = undefined;
      this.locationNameFieldColor = 'green darken-1';
      this.locationAddressFieldColor = 'green darken-1';
    },
    async changeLocationInfo($field) {

      let data = {}
      const locations = [];

      this.locationOffices.map( (location) => {
        locations.push(location.uuid)
      })

      if ($field === 'Location name') {
        data = {
          name: this.locationName,
          address: this.locationAddress,
          offices: locations,
        }
      } else if($field === 'Location address') {
        data = {
          name: this.locationName,
          address: this.locationAddress,
          position: this.locationPosition,
          timeZone: this.locationTimeZone,
          offices: locations,
        }
      } else if($field === 'Location rotate days') {
        data = {
          name: this.locationName,
          address: this.locationAddress,
          rotateDays: this.locationRotateDays ?? 'Always',
          offices: locations,
        }
      } else {
        return false
      }

      const payload = {
        locationUUID: this.uuid,
        changed: 'info',
        data,
      }

      const success = await this.$store.dispatch('UPDATE_LOCATION', payload)

      if (success) {
        this.$emit('set-allowed-close', true);
        this.clearHints();
        this.$eventBus.$emit('emit-success', { state: true, text: `${$field} of ${this.name} was changed successful.` });
        this.updateFromProps()
      } else {
        this.userLocations = this.locations
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while changing ${$field} of ${this.name}.` });
      }
    },
    async deleteLocation() {
      if (confirm(`Are you sure to delete ${this.locationName}?`)) {
        const payload = { locationUUID: this.uuid }
        const success = await this.$store.dispatch('LOCATION_DELETE', payload)

        if (success) {
          this.$eventBus.$emit('emit-success', { state: true, text: `${this.name} REMOVED! .` });
        } else {
          this.$eventBus.$emit('emit-error',
              { state: true, text: `Something went wrong while REMOVING ${this.name}.` });
        }
      } else {
        return false;
      }
    },
    async goToOffice(uuid) {
      this.CLEAR_HISTORY()
      this.UNSUBSCRIBE_ALL()
      this.$store.commit('setOffice', { data: uuid })
      Promise.all([
        this.LOAD_APPOINTMENTS(),
        this.LOAD_DASHBOARD(),
        this.LOAD_DOCTORS(),
        this.LOAD_TREATMENTS(),
        this.SUBSCRIBE_TO_CLIENT_RELOAD({ uuid })
      ])
    },
    async onLocationInputChange(data) {
      this.locationAddressObject = data

      this.locationPosition = {
        lat: data.latitude,
        lng: data.longitude
      };

      try {
        Timezone.key(process.env.VUE_APP_GOOGLE_TIME_STAMP_API_KEY);
        Timezone.data(data.latitude, data.longitude, 0, (err, tz) => {
          console.log(tz.raw_response);
          console.log(tz.local_timestamp);

          this.locationTimeZone = tz.raw_response.timeZoneId

          this.changeLocationInfo('Location address')
        })
      } catch (error) {
        console.log(error)
        this.$eventBus.$emit('emit-error', { state: true, text:"Error handled while getting location's timezone!"})
      }
    },
    onLocationInputValChange(data) {
      this.locationAddress = data.newVal
    },
  }
}
</script>

<style scoped>
.card {
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 2em;
}

.locationNameField {
  max-width: 40%;
}

.locationAddressField {
  max-width: 30%;
  width: 30%;
  margin-left: 20px;
  border: 1px solid #9e9e9e;
  border-radius: 5px;
  position: relative;
}

.addressFieldLabel {
  color: rgba(0, 0, 0, .6);
  font-size: 16px;
  line-height: 24px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 5px;
  transition: all 0.2s ease-in;
}

.addressFieldLabel__top {
  top: -24%;
  transform: unset;
  background: #ffffff;
  padding: 0 2px;
  font-size: 12px;
}

.addressTextField {
  width: 100%;
  height: 100%;
}

.locationRotateField {
  max-width: 20%;
  margin-left: 20px;
  border-radius: 5px;
}

.switchElem {
  margin-top: 4px;
  margin-left: 10px;
}

.buttonAddLocation {
  font-size: 11px;
}

.buttonAddLocation i {
  font-size: 16px;
}

.goToLocation {
  color: blue;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.scroll-section {
  height: calc(90vh - 130px);
  overflow-y: scroll;
}

.scroll-section::-webkit-scrollbar {
  display: block;
  width: 5px;
  background-color: #eaeaea;
  border-radius: 5px;
  margin: 0 10px;
}

.scroll-section::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

.scroll-section::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #4e61b1;
}
</style>
