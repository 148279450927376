<template>
  <v-card
      flat
      class="card w-full pt-1 pb-5"
  >
    <div class="flex justify-start">
      <v-text-field
          label="Name"
          ref="userName"
          class="userNameField pl-2"
          inline
          outlined
          :disabled="!ableToChangeCurrentCard"
          :color="userNameFieldColor"
          v-model="userName"
          :hint="userNameFieldHint"
          :persistent-hint="!!userNameFieldHint"
          :append-icon="userNameAppendIcon"
          @focus="userNameAppendIcon = 'mdi-checkbox-marked-circle'"
          @blur="userNameAppendIcon = undefined"
          @click:append="changeUserInfo('User name')"
      />

      <v-text-field
          label="Email"
          ref="userEmail"
          class="userEmailField pl-2"
          inline
          outlined
          :disabled="!ableToChangeCurrentCard"
          :color="userEmailFieldColor"
          v-model="userEmail"
          :hint="userEmailFieldHint"
          :persistent-hint="!!userEmailFieldHint"
          :append-icon="userEmailAppendIcon"
          @focus="userEmailAppendIcon = 'mdi-checkbox-marked-circle'"
          @blur="userEmailAppendIcon = undefined"
          @click:append="changeUserInfo('User email')"
      />

      <v-select
          outlined
          v-if="ableToChangeCurrentCard"
          :items="rolesForSelect"
          item-text="title"
          item-value="title"
          v-model="userRole"
          class="userRolesField pl-2"
          :label="`Permission ${userRole === null ? '(null)' : ''}`"
          @change="changeUserRole"
      />

      <v-text-field
        v-if="!ableToChangeCurrentCard"
        disabled
        :value="userRole"
        class="userRolesField pl-2"
      />

      <v-switch
          v-model="userEnabled"
          color="#66AB99"
          class="switchElem pl-2"
          v-if="ableDeleteDisable"
          @change="toggleUserEnabled"
      ></v-switch>

      <v-btn text class="mb-2 p-0 gray--text" @click="deleteUser" v-if="ableDeleteDisable">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
    <div>
      <h5 class="text-gray text-sm">Locations</h5>

      <v-chip
          v-if="userLocations.length > 0"
          v-for="(location, i) in userLocations"
          :key="`user-${userUUID}-location-${location.uuid}-${i}`"
          class="ma-2"
          :close="ableToChangeCurrentCard"
          @click:close="changeLocation('Removing', location.uuid)"
      >
        {{ location.name }}
      </v-chip>

      <v-menu
          :nudge-width="130"
          v-if="locationForSelect.length > 0 && ableToChangeCurrentCard"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
              text
              class="buttonAddLocation"
              x-small
              v-bind="attrs"
              v-on="on">
            <v-icon>mdi-plus</v-icon> Add
          </v-btn>
        </template>

        <v-list>
          <v-list-item
              v-for="(location, i) in locationForSelect"
              :key="`add-location-${location.uuid}-${userUUID}`"
              @click="changeLocation('Adding', location.uuid)"
          >
            <v-list-item-title>
              {{ location.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        text
        class="buttonAddLocation"
        x-small
        v-if="locationForSelect.length > 0 && ableToChangeCurrentCard"
        @click="changeLocation('Adding all')"
      >
        <v-icon>mdi-plus</v-icon> All
      </v-btn>
    </div>

  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "SettingsUserCard",
  props: {
    uuid: String,
    name: String,
    email: String,
    role: String,
    enabled: { type: Boolean, default: false },
    locations: { type: Array, default: [] },
  },
  data() {
    return {
      userUUID: '',
      userName: '',
      userEmail: '',
      userRole: '',
      userEnabled: false,
      userLocations: [],
      userNameFieldColor: '#056C97',
      userNameAppendIcon: undefined,
      userNameFieldHint: undefined,
      userEmailFieldColor: '#056C97',
      userEmailAppendIcon: undefined,
      userEmailFieldHint: undefined,
    }
  },
  mounted() {
    this.updateFromProps()
  },
  watch: {
    userName() {
      if (this.userName === this.name) {
        this.$emit('set-allowed-close', true);
        this.userNameFieldHint = undefined;
      } else {
        this.$emit('set-allowed-close', false);
        this.userNameFieldColor = 'yellow darken-1';
        this.userNameFieldHint = 'NOT SAVED';
      }
    },
    userEmail() {
      if (this.userEmail === this.email) {
        this.$emit('set-allowed-close', true);
        this.userEmailFieldHint = undefined;
      } else {
        this.$emit('set-allowed-close', false);
        this.userEmailFieldColor = 'yellow darken-1';
        this.userEmailFieldHint = 'NOT SAVED';
      }
    },
  },
  computed: {
    ...mapState({
      locationsAll: state => state.locationsAll,
      currentUser: state => state.auth.user,
    }),
    roles() {
      return [
          {level: 1, title: 'Support'},
          {level: 2, title: 'Practitioner'},
          {level: 3, title: 'Manager'},
          {level: 4, title: 'Administrator'},
      ]
    },
    rolesForSelect() {
      return this.roles.filter( (role) => role.level <= this.currentUserRoleLevel);
    },
    currentUserRoleLevel() {
      return this.roles.filter(role => role.title === this.currentUser.role)[0].level;
    },
    cardUserRoleLevel() {
      return this.roles.filter(role => role.title === this.userRole)[0]?.level || 0;
    },
    ableToChangeCurrentCard() {
      return this.cardUserRoleLevel <= this.currentUserRoleLevel
    },
    locationForSelect() {
      const locationUUIDs = []
      const locationsSelect = this.currentUser.role === 'Administrator' ? this.locationsAll : this.currentUser.locations
      this.userLocations.map( location => locationUUIDs.push(location.uuid) )
      return locationsSelect.filter((location) => !locationUUIDs.includes(location.uuid))
    },
    ableDeleteDisable() {
      return this.uuid !== this.currentUser.uuid && this.ableToChangeCurrentCard
    }
  },
  methods: {
    updateFromProps() {
      this.userUUID = this.uuid
      this.userName = this.name
      this.userEmail = this.email
      this.userRole = this.role
      this.userEnabled = this.enabled
      this.userLocations = this.locations
    },
    clearHints() {
      this.userNameFieldHint = undefined;
      this.userEmailFieldHint = undefined;
      this.userNameFieldColor = 'green darken-1';
      this.userEmailFieldColor = 'green darken-1';
    },
    async toggleUserEnabled() {

      let action = '';

      if (this.userEnabled){
        action = 'enable'
      } else {
        action = 'disable'
      }

      const payload = {
        userUUID: this.userUUID,
        action: action,
      }

      const success = await this.$store.dispatch('USER_ACTIVITY_UPDATE', payload)

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `User ${this.name} ${action}d successful .` });
        this.updateFromProps()
      } else {
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while ${action} user ${this.name}.` });
        this.updateFromProps()
      }

    },
    async changeLocation(action, uuid = '') {
      let userLocations = []

      if (action === 'Removing') {
        this.userLocations.filter((location) => location.uuid !== uuid).map((location) => {
          userLocations.push(location.uuid)
        })
      } else if(action === 'Adding') {

        this.userLocations.map((location) => {
          userLocations.push(location.uuid)
        })
        userLocations = [...userLocations, uuid]

      } else if(action === 'Adding all') {

        this.locationsAll.map((location) => {
          userLocations.push(location.uuid)
        })
      } else {
        return false
      }

      const payload = {
        userUUID: this.userUUID,
        data: {
          name: this.name,
          email: this.email,
          role: this.role ?? 'Support',
          offices: userLocations ?? null,
        }
      }

      const success = await this.$store.dispatch('UPDATE_USER', payload)

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `${action} location to ${this.name}.` });
        this.updateFromProps()
      } else {
        this.userLocations = this.locations
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while ${action} location to ${this.name}.` });
      }
    },
    async changeUserInfo($field) {

      let name = '';
      let email = '';

      if ($field === 'User name') {
        name = this.userName
        email = this.email
      } else if($field === 'User email') {
        name = this.name
        email = this.userEmail
      } else {
        return false
      }

      const payload = {
        userUUID: this.userUUID,
        data: {
          name: name,
          email: email,
          role: this.role ?? 'Support',
        }
      }

      const success = await this.$store.dispatch('UPDATE_USER', payload)

      if (success) {
        this.$emit('set-allowed-close', true);
        this.clearHints();
        this.$eventBus.$emit('emit-success', { state: true, text: `${$field} of ${this.name} was changed successful.` });
        this.updateFromProps()
      } else {
        this.userLocations = this.locations
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while changing ${$field} of ${this.name}.` });
      }
    },
    async changeUserRole() {

      const payload = {
        userUUID: this.userUUID,
        data: {
          name: this.name,
          email: this.email,
          role: this.userRole,
        }
      }

      const success = await this.$store.dispatch('UPDATE_USER', payload)

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `Changing role of ${this.name} was succeed .` });
      } else {
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while changing role of ${this.name}.` });
      }

      this.updateFromProps()
    },
    async deleteUser() {
      if (confirm(`Are you sure to delete ${this.userName}?`)) {
        const payload = { userUUID: this.userUUID }
        const success = await this.$store.dispatch('USER_DELETE', payload)

        if (success) {
          this.$eventBus.$emit('emit-success', { state: true, text: `${this.name} REMOVED! .` });
        } else {
          this.$eventBus.$emit('emit-error',
              { state: true, text: `Something went wrong while REMOVING ${this.name}.` });
        }
      } else {
        return false;
      }
    }
  }
}
</script>

<style scoped>
  .card {
    border-bottom: 1px solid #d0d0d0;
    margin-bottom: 2em;
  }

  .userNameField {
    max-width: 25%;
  }

  .userEmailField {
    max-width: 30%;
  }

  .userRolesField {
    max-width: 20%;
  }

  .switchElem {
    margin-top: 4px;
    margin-left: 10px;
  }

  .buttonAddLocation {
    font-size: 11px;
  }

  .buttonAddLocation i {
    font-size: 16px;
  }
</style>
