
















































import {mapState} from "vuex";

export default {
  name: "SettingsPracticeCard",
  props: {
    uuid: String,
    name: String,
    enabled: { type: Boolean, default: false },
    updatedAt: String,
    externalId: Number,
    drchronoName: String,
  },
  data() {
    return {
      practiceName: '',
      practiceEnabled: false,
      practiceNameFieldColor: '#056C97',
      practiceNameAppendIcon: undefined,
      practiceNameFieldHint: undefined,
      practiceDrChronoOffice: undefined,
      practiceDrChronoOfficeName: undefined,
    }
  },
  mounted() {
    this.updateFromProps()
  },
  computed: {
    ...mapState({
      // @ts-ignore
      practicesAll: state => state.practicesAll,
    }),
  },
  watch: {
    practicesAll() {
      this.updateFromProps();
    },
    practiceName() {
      if (this.practiceName === this.name) {
        this.$emit('set-allowed-close', true);
        this.practiceNameFieldHint = undefined;
      } else {
        this.$emit('set-allowed-close', false);
        this.practiceNameFieldColor = 'yellow darken-1';
        this.practiceNameFieldHint = 'NOT SAVED';
      }
    }
  },
  methods: {
    updateFromProps() {
      this.practiceName = this.name
      this.practiceEnabled = this.enabled
      this.practiceDrChronoOffice = this.externalId
      this.practiceDrChronoOfficeName = this.drchronoName
    },
    async togglePracticeEnabled() {

      let action = '';

      if (this.practiceEnabled){
        action = 'enable'
      } else {
        action = 'disable'
      }

      const payload = {
        practiceUUID: this.uuid,
        action: action,
      }

      const success = await this.$store.dispatch('PRACTICE_ACTIVITY_UPDATE', payload)

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `Practice ${this.name} ${action}d successful .` });
        this.updateFromProps()
      } else {
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while ${action} practice ${this.name}.` });
        this.updateFromProps()
      }

    },
    clearHints() {
      this.practiceNameFieldHint = undefined;
      this.practiceNameFieldColor = 'green darken-1';
    },
    async changePracticeInfo() {

      let payload = {}

      if (this.externalId) {
        payload = {
          practiceUUID: this.uuid,
          data: {
            name: this.practiceName,
          }
        }
      } else {
        const drChrono = this.practiceCandidates.filter( pC => pC.id === this.practiceDrChronoOffice)[0]
        console.log('drChrono - ', drChrono.name)

        payload = {
          practiceUUID: this.uuid,
          data: {
            name: this.practiceName,
          }
        }
      }

      const success = await this.$store.dispatch('UPDATE_PRACTICE', payload)

      if (success) {
        this.$emit('set-allowed-close', true);
        this.clearHints();
        this.$eventBus.$emit('emit-success', { state: true, text: `Practice name of ${this.practiceName} was changed successful.` });
        this.updateFromProps()
      } else {
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while changing Practice name of ${this.name}.` });
      }
    },
    async deletePractice() {
      if (confirm(`Are you sure to delete ${this.practiceName}?`)) {
        const payload = { practiceUUID: this.uuid }
        const success = await this.$store.dispatch('PRACTICE_DELETE', payload)

        if (success) {
          this.$eventBus.$emit('emit-success', { state: true, text: `${this.name} REMOVED! .` });
        } else {
          this.$eventBus.$emit('emit-error',
              { state: true, text: `Something went wrong while REMOVING ${this.name}.` });
        }
      } else {
        return false;
      }
    }
  }
}
