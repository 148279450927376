













































































import Vue from 'vue';
import {mapState} from 'vuex';
import Profile from '@/components/modules/Profile.vue';
import UsersSettings from '@/components/modules/UsersSettings.vue';
import LocationSettings from '@/components/modules/LocationSettings.vue';
import PracticesSettings from '@/components/modules/PracticesSettings.vue';
import {mdiClose} from '@mdi/js';
import {boolean} from 'boolean';
import AppointmentTreatmentSelector from '@/components/modules/AppointmentTreatmentSelector.vue';

export default Vue.extend({
  name: 'ProfileSettingsDialog',
  components: {AppointmentTreatmentSelector, Profile, UsersSettings, LocationSettings, PracticesSettings},
  data() {
    return {
      dialog: true,
      subDialog: false,
      closeIcon: mdiClose,
      step: 0,
      activeTab: 'Treatments',
      showAddItem: false,
      manageUsers: false,
      configureBoard: false,
      allowedToClose: true,
    }
  },
  computed: {
    ...mapState({
      // @ts-ignore
      user: state => state.auth.user,
    }),
  },
  async mounted() {
    this.manageUsers = await this.$store.dispatch('ableUserTo','manage users')
    this.configureBoard = await this.$store.dispatch('ableUserTo','configure board')
  },
  methods: {
    onCloseProfileSettingsDialog() {
      this.$emit('close-profile-settings-dialog')
    },
    closeModal() {
      if (this.allowedToClose) {
        this.$emit('close-profile-settings-dialog')
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: 'Please, save your changes before closing modal' });
      }
    },
    setAllowedToClose(value: boolean) {
      this.allowedToClose = value;
    },
  }
})
