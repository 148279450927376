
































































import {mapState} from "vuex";
import SettingsPracticeCard from "@/components/cards/SettingsPracticeCard.vue";

export default {
  name: "PracticesSettings",
  components: {SettingsPracticeCard},
  props: {
    createPractice: { type: Boolean, default: false }
  },
  data() {
    return {
      dialog: false,
      practiceName: undefined,
      practiceDrChronoOffice: undefined,
    }
  },
  computed: {
    ...mapState({
      // @ts-ignore
      practicesAll: state => state.practicesAll,
      // @ts-ignore
      practiceCandidates: state => state.practiceCandidates,
    }),
  },
  mounted() {
    this.$store.dispatch('GET_ALL_PRACTICES')
  },
  watch: {
    createPractice() {
      this.$store.dispatch('GET_ALL_PRACTICES_CANDIDATES')
      this.dialog = this.createPractice;
    },
    practicesAll() {
      this.practicesAllForSelect = this.practicesAll;
    },
  },
  methods: {
    setAllowedToClose(value: boolean) {
      this.$emit('set-allowed-close', value);
    },
    async addNewPractice() {

      const drChrono = this.practiceCandidates.filter( pC => pC.id === this.practiceDrChronoOffice)[0]
      console.log('drChrono - ', drChrono.name)

      const payload = {
        practiceUUID: this.uuid,
        data: {
          name: drChrono.name,
          drchronoName: drChrono.name,
          drchronoId: drChrono.id,
        }
      }

      const success = await this.$store.dispatch('PRACTICE_CREATE', payload)

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `${this.practiceName} created! .` });
        this.clearForm()
        this.$emit('close-dialog')
      } else {
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while creating on ${this.practiceName}.` });
      }

    },
    clearForm() {
      this.practiceName = undefined
    }
  }
}
