






























































































































import SettingsUserCard from '@/components/cards/SettingsUserCard.vue';
import {mapState} from "vuex";
import {boolean} from "boolean";
import PracticesSettings from '@/components/modules/PracticesSettings.vue';


export default {
  name: "UsersSettings",
  components: {PracticesSettings, SettingsUserCard },
  data() {
    return {
      dialog: false,
      userName: undefined,
      userEmail: undefined,
      userRole: undefined,
      userLocations: undefined,
      passwordFieldShow: false,
      passwordFieldHint: 'At Least 6 symbols',
      passwordConfirmationFieldHint: undefined,
      profilePasswordDefault: 'Password123!',
      profilePassword: 'Password123!',
      profilePasswordConfirmation: undefined,
      passwordColor: '#056C97',
      passwordConfirmationColor: '#056C97',
    }
  },
  props: {
    createUser: { type: Boolean, default: false }
  },
  watch: {
    createUser() {
      this.dialog = this.createUser;
    }
  },
  computed: {
    ...mapState({
      // @ts-ignore
      users: state => state.users,
      // @ts-ignore
      locationsAll: state => state.locationsAll,
      // @ts-ignore
      currentUser: state => state.auth.user,
    }),
    roles() {
      return [
        {level: 1, title: 'Support'},
        {level: 2, title: 'Practitioner'},
        {level: 3, title: 'Manager'},
        {level: 4, title: 'Administrator'},
      ]
    },
    rolesForSelect() {
      return this.roles.filter( (role) => role.level <= this.currentUserRoleLevel);
    },
    currentUserRoleLevel() {
      return this.roles.filter(role => role.title === this.currentUser.role)[0].level;
    },
  },
  mounted() {
    this.$store.dispatch('GET_ALL_LOCATIONS')
    this.$store.dispatch('ALL_USERS')
  },
  methods: {
    setAllowedToClose(value: boolean) {
      this.$emit('set-allowed-close', value);
    },
    async addNewUser() {

      let passwordConfirmed = false

      if (this.profilePassword === this.profilePasswordDefault) {
        passwordConfirmed = !passwordConfirmed
      }

      if (this.profilePassword.length < 6) {
        this.$refs.profilePasswordInput.focus()
        this.passwordFieldHint = "Password should contains at least 6 symbols!"
        this.passwordColor = 'red darken-1'
        return false
      }

      if (!passwordConfirmed && this.profilePassword !== this.profilePasswordConfirmation) {
        this.$refs.profilePasswordConfirmationInput.focus()
        this.passwordConfirmationFieldHint = "Password confirmation does not match"
        this.passwordConfirmationColor = 'red darken-1'

        return false
      }

      const payload = {
        data: {
          name: this.userName,
          email: this.userEmail,
          role: this.userRole,
          offices: this.userLocations,
          password: this.profilePassword,
          password_confirmation: passwordConfirmed ? this.profilePassword : this.profilePasswordConfirmation,
        }
      }

      const success = await this.$store.dispatch('USER_CREATE', payload)

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `${this.userName} created! .` });
        this.clearForm()
        this.$emit('close-dialog')
      } else {
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while creating user ${this.userName}.` });
      }

    },
    clearForm() {
      this.userName = undefined
      this.userEmail = undefined
      this.userRole = undefined
      this.userLocations = undefined
      this.profilePassword = undefined
      this.profilePasswordConfirmation = undefined
    }
  }
}
