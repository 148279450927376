




































































































import {mapState} from "vuex";
import SettingsLocationCard from "@/components/cards/SettingsLocationCard.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Timezone from "node-google-timezone";

export default {
  name: "LocationSettings",
  components: {SettingsLocationCard, VueGoogleAutocomplete },
  props: {
    createLocation: { type: Boolean, default: false }
  },
  data() {
    return {
      dialog: false,
      locationName: undefined,
      locationAddress: undefined,
      locationTimeZone: undefined,
      locationOffices: undefined,
      locationAllForSelect: undefined,
      fieldsForAutocomplete:["address_components", "geometry", "icon", "name"],
    }
  },
  computed: {
    ...mapState({
      // @ts-ignore
      locationsAll: state => state.locationsAll,
      // @ts-ignore
      practicesAll: state => state.practicesAll,
    }),
  },
  mounted() {
    this.$store.dispatch('GET_ALL_LOCATIONS')
  },
  watch: {
    createLocation() {
      this.dialog = this.createLocation;
    },
    locationsAll() {
      this.locationAllForSelect = this.locationsAll;
    },
  },
  methods: {
    setAllowedToClose(value: boolean) {
      this.$emit('set-allowed-close', value);
    },
    async addNewLocation() {
      const payload = {
        locationUUID: this.uuid,
        data: {
          name: this.locationName,
          address: this.locationAddress,
          timeZone: this.locationTimeZone,
          offices: this.locationOffices ?? [],
        }
      }

      const success = await this.$store.dispatch('LOCATION_CREATE', payload)

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `${this.locationName} created! .` });
        this.clearForm()
        this.$emit('close-dialog')
      } else {
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while creating location on ${this.locationAddress}.` });
      }

    },
    async onLocationInputChange(data) {
      this.locationAddressObject = data
      console.log(data)

      try {
        Timezone.key(process.env.VUE_APP_GOOGLE_TIME_STAMP_API_KEY);
        Timezone.data(data.latitude, data.longitude, 0, (err, tz) => {
          console.log(tz.raw_response);
          console.log(tz.local_timestamp);

          this.locationTimeZone = tz.raw_response.timeZoneId
        })
      } catch (error) {
        console.log(error)
        this.$eventBus.$emit('emit-error', { state: true, text:"Error handled while getting location's timezone!"})
      }
    },
    onLocationInputValChange(data) {
      this.locationAddress = data.newVal
    },
    clearForm() {
      this.locationName = undefined
      this.locationAddress = undefined
      this.locationOffices = undefined
      this.$refs['locationAddress'].clear();
      this.locationTimeZone = undefined
    }
  }
}
