<template>
  <div>
    <div class="py-4 px-4 pb-0 md:px-0 w-full lighten-1 flex justify-space-between mt-4">

      <!--  User Name  -->
      <v-text-field
          label="Name"
          v-model="profile.name"
          inline
          outlined
          :color="nameColor"
          :append-icon="appendIconName"
          :hint="profileNameHint"
          :persistent-hint="!!profileNameHint"
          class="profilePageElement"
          @focus="appendIconName = 'mdi-checkbox-marked-circle'"
          @blur="appendIconName = undefined"
      >
        <template v-slot:append>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="updateNameEmail($event)"
                  style="display: inline-block; padding: 0; min-width: unset; height: unset"
              >
                <v-icon
                    color="#2F6B93"
                    v-if="appendIconName"
                >
                  mdi-checkbox-marked-circle</v-icon>
              </v-btn>
            </template>
            <span>Tap to accept</span>
          </v-tooltip>
        </template>
      </v-text-field>

      <!--  User Email  -->
      <v-text-field
          label="Email"
          class="profilePageElement"
          v-model="profile.email"
          inline
          outlined
          :color="emailColor"
          :hint="profileEmailHint"
          :persistent-hint="!!profileEmailHint"
          :append-icon="appendIconEmail"
          @focus="appendIconEmail = 'mdi-checkbox-marked-circle'"
          @blur="appendIconEmail = undefined"
      >
        <template v-slot:append>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="updateNameEmail($event)"
                  style="display: inline-block; padding: 0; min-width: unset; height: unset"
              >
                <v-icon
                    color="#2F6B93"
                    v-if="appendIconEmail"
                >
                  mdi-checkbox-marked-circle</v-icon>
              </v-btn>
            </template>
            <span>Tap to accept</span>
          </v-tooltip>
        </template>
      </v-text-field>

    </div>
    <div class="py-4 px-4 md:px-0 w-full lighten-1 flex justify-space-between">

      <!--  User Password  -->
      <div
          v-if="changePasswordStart"
          class="profilePageElementContainer">

        <v-text-field
            label="Previous password"
            ref="prevProfilePasswordInput"
            class="profilePasswordFields"
            :type="previousPasswordShow ? 'text' : 'password'"
            inline
            outlined
            :color="prevPasswordFieldColor"
            v-model="prevProfilePassword"
            :append-icon="prevPasswordCheckingIcon"
            @click:append="checkPassword"
            @focus="prevPasswordCheckingIcon = 'mdi-check'"
            :hint="prevPasswordFieldHint"
            :persistent-hint="!!prevPasswordFieldHint"
            @blur="prevPasswordCheckingIcon = undefined"
        ></v-text-field>

        <v-text-field
            v-if="prevPasswordCorrect"
            label="Password"
            ref="profilePasswordInput"
            class="profilePasswordFields"
            :type="passwordFieldShow ? 'text' : 'password'"
            inline
            outlined
            :color="passwordColor"
            v-model="profilePassword"
            :append-icon="passwordFieldShow ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="passwordFieldShow = !passwordFieldShow"
            @focus="appendIconPassword = 'mdi-eye'"
            :hint="passwordFieldHint"
            :persistent-hint="!!passwordFieldHint"
            @blur="appendIconPassword = undefined"
        ></v-text-field>

        <v-text-field
            v-if="![undefined, '', null].includes(profilePassword)"
            label="Confirm Password"
            ref="profilePasswordConfirmationInput"
            class="profilePasswordFields"
            type="password"
            inline
            outlined
            :color="passwordConfirmationColor"
            v-model="profilePasswordConfirmation"
            :hint="passwordConfirmationFieldHint"
            :persistent-hint="!!passwordConfirmationFieldHint"
        ></v-text-field>
      </div>

      <div class="profilePageElement changePasswordButton" v-if="!changePasswordStart">
        <span @click="changePasswordStart = true">Change Password</span>
      </div>

      <div class="profilePageElement changePasswordButton"
           v-if="allowToSave">
        <v-btn
            v-on:click="showAddItem = true"
            color="#2F6B93"
            style="color: white"
            @click="updatePassword"
        >
          <div class="flex items-center">
            <span class="mr-1">Save Password</span>
            <v-icon>mdi-account-edit</v-icon>
          </div>
        </v-btn>
      </div>



    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Profile",
  data() {
    return {
      profile: {},
      appendIconName: undefined,
      appendIconEmail: undefined,
      appendIconPassword: undefined,

      changePasswordStart: false,
      previousPasswordShow: false,
      prevProfilePassword: undefined,
      prevPasswordFieldHint: 'Enter previous password to change password',
      prevPasswordFieldColor: '#056C97',
      prevPasswordCheckingIcon: undefined,
      prevPasswordCorrect: false,

      passwordFieldShow: false,
      passwordFieldHint: 'At Least 6 symbols',
      passwordConfirmationFieldHint: undefined,
      profilePasswordDefault: undefined,
      profilePassword: undefined,
      profilePasswordConfirmation: undefined,
      profileNameHint: undefined,
      profileEmailHint: undefined,
      nameColor: '#056C97',
      emailColor: '#056C97',
      passwordColor: '#056C97',
      passwordConfirmationColor: '#056C97',
    }
  },
  computed: {
    ...mapState({
      // @ts-ignore
      user: state => state.auth.user,
    }),
    allowToSave() {
        return this.changePasswordStart
            && this.prevPasswordCorrect
            && this.profilePassword?.length >= 6
            && this.profilePassword === this.profilePasswordConfirmation
    }
  },
  mounted() {
    this.profile = this.user
  },
  watch: {
    user() {
      this.profile = this.user
    }
  },
  methods: {
    async checkPassword() {
        if ([undefined, '', null].includes(this.prevProfilePassword)) {
            this.prevPasswordFieldHint = 'Enter password to check!';
            this.prevPasswordFieldColor = 'red darken-1';
            return false;
        }

        const payload = {
            data: {
                password: this.prevProfilePassword,
            }
        };

        const success = await this.$store.dispatch('USER_PASSWORD_CHECK', payload);

        if (success) {
            this.prevPasswordCorrect = true;
            this.prevPasswordFieldHint = 'Password Correct!';
            this.prevPasswordFieldColor = 'green darken-1';
        } else {
            this.prevPasswordFieldHint = 'Your password is incorrect. If you\'ve forgotten your password, please log out and then click on the \'Forgot password\' link on the login page. Enter your email address to initiate the password recovery process.';
            this.prevPasswordFieldColor = 'red darken-1';
        }
    },
    async updatePassword() {

      if (this.profilePassword === this.profilePasswordDefault) {
        this.$refs.profilePasswordInput.focus()
        this.passwordFieldHint = "Edit password to make changes"
        this.passwordColor = 'red darken-1'
        return false
      }

      if (this.profilePassword.length < 6) {
        this.$refs.profilePasswordInput.focus()
        this.passwordFieldHint = "Password should contains at least 6 symbols!"
        this.passwordColor = 'red darken-1'
        return false
      }

      if (this.profilePassword !== this.profilePasswordConfirmation) {
        this.$refs.profilePasswordConfirmationInput.focus()
        this.passwordConfirmationFieldHint = "Password confirmation does not match"
        this.passwordConfirmationColor = 'red darken-1'

        return false
      }

      if (confirm('Are you sure to change password?')) {
        const payload = {
          userUUID: this.user.uuid,
          data: {
            password: this.profilePassword,
            password_confirmation: this.profilePasswordConfirmation,
          }
        };

        const success = await this.$store.dispatch('USER_PASSWORD_CHANGE', payload)

        if (success) {
          this.changePasswordStart = false;
          this.profilePasswordConfirmation = ''
          this.$refs.profilePasswordInput.focus()
          this.passwordFieldHint = 'Password was changed'
          this.passwordColor = 'green darken-1'
        } else {
          this.$refs.profilePasswordInput.focus()
          this.passwordFieldHint = "Couldn\'t change password"
          this.passwordColor = 'red darken-1'
        }
      }
    },
    async updateNameEmail() {
      const payload = {
        userUUID: this.user.uuid,
        data: {
          name: this.profile.name,
          email: this.profile.email,
          role: this.profile.role,
        }
      }

      const success = await this.$store.dispatch('UPDATE_USER', payload)

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `Profile info of ${this.profile.name} was changed successful.` });
      } else {
        this.userLocations = this.locations
        this.$eventBus.$emit('emit-error',
            { state: true, text: `Something went wrong while changing profile info of ${this.profile.name}.` });
      }
    }
  }
}
</script>

<style scoped>
  .profilePageElement {
    max-width: 48%;
  }
  .profilePageElementContainer {
    width: 48%;
  }
  .profilePasswordFields {
    width: 100%;
  }
  .changePasswordButton {
    color: #056C97;
    width: 48%;
    line-height: 3em;
    text-decoration: underline;
    cursor: pointer;
  }
</style>
